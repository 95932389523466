import React, { useState } from 'react';
import { Button, DatePicker } from "@heroui/react";
import NewSubjectFormContainer from './styled-components/NewSubjectFormContainer';
import { Input, Radio, RadioGroup } from '@nextui-org/react';
import AutocompleteProfessor from './components/AutocompleteProfessor';
import { enqueueSnackbar } from 'notistack';
import createSubject from '@services/subjects/createSubject';
import { useMutation } from 'react-query';

function NewSubjectForm() {
  const [subjectName, setSubjectName] = useState();
  const [minToApprove, setMinToApprove] = useState(0);
  const [exonerable, setExonerable] = useState();
  const [dateStart, setDateStart] = useState();
  const [dateFinish, setDateFinish] = useState();
  const [idProfessor, setIdProfessor] = useState();
  const [cost, setCost] = useState(0);
  const [videosNumber, setVideosNumber] = useState(0);

  const [showMinimumToApprove, setShowMinimumToApprove] = useState(false);
  const [hasEvaluation, setHasEvaluation] = useState();

  const resetForm = () => {
    setSubjectName('');
    setMinToApprove(0);
    setExonerable('');
    setDateStart('');
    setDateFinish('');
    setIdProfessor('');
    setCost(0);
    setVideosNumber(0);
  };


  const { mutate: onNewSubject, isLoading: isLoadingNewSubject } =
    useMutation(createSubject, {
      onSuccess: (data) => {
        if (data?.status === 200) {
          enqueueSnackbar("Materia creada", {
            variant: "success",
            persist: false,
          });
          resetForm();
        }
        else {
          enqueueSnackbar(data?.data?.message, {
            variant: "error",
            persist: false,
          });
        }
      }
    });


  const onEvaluationModeChange = (evaluationTarget) => {
    setHasEvaluation(evaluationTarget)
    if (evaluationTarget) {
      setExonerable(false);
      setShowMinimumToApprove(true)
    }
    if (!evaluationTarget) {
      setShowMinimumToApprove(false)
      setMinToApprove(0)
      setExonerable(true)
    }
  };

  const onDateChanged = (target, isStart) => {
    if (target?.day && target?.month && target?.year) {
      const { day, month, year } = target;
      const formattedDay = day < 10 ? `0${day}` : day;
      const formattedMonth = month < 10 ? `0${month}` : month;
      if (isStart)
        setDateStart(`${formattedDay}/${formattedMonth}/${year}`)
      else
        setDateFinish(`${formattedDay}/${formattedMonth}/${year}`)
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Aquí puedes realizar otras acciones con los datos, como enviarlos a un servidor
    const dataToSend = { name: subjectName, minToApprove, exonerable, dateStart, dateFinish, idProfessor, cost, visible: false, videosNumber }
    onNewSubject(dataToSend)
  };

  return (
    <NewSubjectFormContainer onSubmit={handleSubmit} className='gap-4'>
      <div className='w-full md:w-2/4 bg-emerald-700 md:m-5 md:p-5 rounded-xl'>
        <p className='text-3xl font-bold text-center my-4'>Alta de Materia</p>
        <div className='flex flex-col gap-2'>
          <Input
            type="text"
            value={subjectName}
            label="Nombre"
            isRequired
            onChange={(e) => setSubjectName(e.target.value)}
          />
          <div className='flex items-center gap-6'>
            <div className="flex flex-col gap-3 bg-white rounded-lg p-3 w-full">
              <RadioGroup
                label="Modo de Evaluación"
                value={hasEvaluation}
                onValueChange={onEvaluationModeChange}
                isRequired
              >
                <Radio value={true}>Exámen</Radio>
                <Radio value={false}>Vista de videos</Radio>
              </RadioGroup>
            </div>
            {showMinimumToApprove &&
              <Input
                type="number"
                isRequired
                value={minToApprove}
                label="Mínimo para aprobar"
                onChange={(e) => setMinToApprove(parseInt(e.target.value))}
              />
            }
          </div>
          <div className='flex gap-3'>
            <div className="flex w-full flex-wrap md:flex-nowrap gap-4">
              <DatePicker isRequired className="max-w-[284px]" label="Fecha de comienzo" onChange={(target) => onDateChanged(target, true)} />
            </div>
            <div className="flex w-full flex-wrap md:flex-nowrap gap-4">
              <DatePicker isRequired className="max-w-[284px]" label="Fecha de fin" onChange={(target) => onDateChanged(target, false)} />
            </div>
          </div>
          <AutocompleteProfessor idProfessor={idProfessor} setIdProfessor={setIdProfessor} />
          <Input
            type="number"
            isRequired
            value={cost}
            label="Costo de la materia"
            onChange={(e) => setCost(parseInt(e.target.value))}
          />
          <Input
            type="number"
            isRequired
            value={videosNumber}
            label="Cantidad de clases"
            onChange={(e) => setVideosNumber(parseInt(e.target.value))}
          />
        </div>

        <div className='flex gap-4 w-full items-center justify-center'>
          <Button className='mt-8' type="button" onPress={resetForm} variant='shadow' color='warning' isLoading={isLoadingNewSubject} >Limpiar</Button>
          <Button className='mt-8' type="submit" variant='shadow' color='secondary' isLoading={isLoadingNewSubject} >Guardar</Button>
        </div>
      </div>

    </NewSubjectFormContainer>
  );
}

export default NewSubjectForm;