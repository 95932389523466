import axios from "../axios";
import { getProfessorsURL } from "./URLs";

const getProfessors = async () => {
  try {
    const response = await axios().get(getProfessorsURL);
    return response.data || {};
  } catch {
    return {};
  }
};

export default getProfessors;
