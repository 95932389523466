import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup, DatePicker, Select, SelectItem } from "@heroui/react";
import NewSubjectFormContainer from './styled-components/NewSubjectFormContainer';
import { Input } from '@nextui-org/react';
import { enqueueSnackbar } from 'notistack';
import createSubject from '@services/subjects/createSubject';
import { useMutation } from 'react-query';
import { PAYMENT_PLAN } from '@utils/constants';
import { CustomCheckbox } from './components/CustomCheckBox/CustomCheckBox';
import SelectSubjectsModal from './components/SelectSubjectsModal';
import getSubjects from '@services/subjects/getSubjects';
import doCreateCourse from '@services/courses/doCreateCourse';

const animals = [
  { key: "cat", label: "Cat" },
  { key: "dog", label: "Dog" },
  { key: "elephant", label: "Elephant" },
  { key: "lion", label: "Lion" },
  { key: "tiger", label: "Tiger" },
  { key: "giraffe", label: "Giraffe" },
  { key: "dolphin", label: "Dolphin" },
  { key: "penguin", label: "Penguin" },
  { key: "zebra", label: "Zebra" },
  { key: "shark", label: "Shark" },
  { key: "whale", label: "Whale" },
  { key: "otter", label: "Otter" },
  { key: "crocodile", label: "Crocodile" },
];



function NewCourseForm() {
  const [courseName, setCourseName] = useState();
  const [dateStart, setDateStart] = useState();
  const [dateFinish, setDateFinish] = useState();
  const [registrationCost, setRegistrationCost] = useState(0);
  const [paymentPlan, setPaymentPlan] = useState(0);
  const [selectedSubjects, setSelectedSubjects] = React.useState(new Set([]));

  const [subjectModalIsVisible, setSubjectModalIsVisible] = React.useState(false);

  const [subjectsDataBase, setSubjectsDataBase] = useState([]);


  useEffect(() => {
    fetchSubjects();
  }, [])


  const resetFormCourse = () => {
    setCourseName('')
    setDateStart('')
    setDateFinish('')
    setRegistrationCost(0);
    setPaymentPlan(0)
    setSelectedSubjects(new Set([]));
  };

  const onSelectPaymentPlan = (paymentPlan) => {
    if (paymentPlan?.currentKey) setPaymentPlan(parseInt(paymentPlan.currentKey))
  }

  const onDateChangedCourse = (target, isStart) => {
    const { day, month, year } = target;
    if (day && month && year) {
      const formattedDay = day < 10 ? `0${day}` : day;
      const formattedMonth = month < 10 ? `0${month}` : month;
      if (isStart)
        setDateStart(`${formattedDay}/${formattedMonth}/${year}`)
      else
        setDateFinish(`${formattedDay}/${formattedMonth}/${year}`)
    }
  };

  const formatSubjects = (ids, subjects) => {
    // Convierte los IDs a números para una comparación precisa
    // const idsNumericos = ids.map(id => parseInt(id, 10));

    // Filtra los objetos que coinciden con los IDs
    const objetosFiltrados = subjects.filter(sub => ids.includes(sub.id));
    debugger;

    return objetosFiltrados;
  }

  const { mutate: fetchSubjects } =
    useMutation(getSubjects, {
      onSuccess: (data) => {
        setSubjectsDataBase(data.data.data);
      }
    });

  const { mutate: onNewCourse, isLoading: isLoadingNewSubject } =
    useMutation(doCreateCourse, {
      onSuccess: (data) => {
        if (data?.status === 200) {
          enqueueSnackbar("Curso Creado", {
            variant: "success",
            persist: false,
          });
          resetFormCourse();
        }
        else {
          enqueueSnackbar(data?.data?.message, {
            variant: "error",
            persist: false,
          });
        }
      }
    });


  const handleSubmit = (event) => {
    event.preventDefault();
    const subjectsStrings = Array.from(selectedSubjects, String);
    debugger;
    const dataToSend = { name: courseName, dateStart, dateFinish, visible: true, subjects: subjectsStrings, registrationCost, paymentPlan }

    onNewCourse(dataToSend)
  };

  return (
    <NewSubjectFormContainer onSubmit={handleSubmit} className='gap-4'>

      <SelectSubjectsModal
        visible={subjectModalIsVisible}
        closeHandler={() => setSubjectModalIsVisible(false)}
        subjects={subjectsDataBase}
        selectedKeys={selectedSubjects}
        setSelectedKeys={setSelectedSubjects}
      />

      <div className='w-full md:w-2/4 bg-emerald-700 md:m-5 md:p-5 rounded-xl'>
        <p className='text-3xl font-bold text-center my-4'>Alta de Curso</p>
        <div className='flex flex-col gap-2'>
          <Input
            type="text"
            value={courseName}
            label="Nombre"
            isRequired
            onChange={(e) => setCourseName(e.target.value)}
          />

          <div className='flex gap-3'>
            <div className="flex w-full flex-wrap md:flex-nowrap gap-4">
              <DatePicker isRequired className="max-w-[284px]" label="Fecha de comienzo" onChange={(target) => onDateChangedCourse(target, true)} />
            </div>
            <div className="flex w-full flex-wrap md:flex-nowrap gap-4">
              <DatePicker isRequired className="max-w-[284px]" label="Fecha de fin" onChange={(target) => onDateChangedCourse(target, false)} />
            </div>
          </div>

          <Input
            type="number"
            isRequired
            value={registrationCost}
            label="Matrícula"
            onChange={(e) => setRegistrationCost(parseInt(e.target.value))}
          />

          <div className="flex w-full flex-wrap md:flex-nowrap gap-4">
            <Select className="max-w-xs" label="Plan de pagos" onSelectionChange={onSelectPaymentPlan}>
              {PAYMENT_PLAN.map((animal) => (
                <SelectItem className='text-black' key={animal.key}>{animal.label}</SelectItem>
              ))}
            </Select>
          </div>


          <div className='flex gap-3 items-center mt-8'>
            {selectedSubjects.size > 0 &&
              <div className='bg-white p-3 rounded-xl'>
                <p className='text-black'>Cantidad de materias: <p className='font-bold'>{selectedSubjects?.size}</p></p>
              </div>
            }
            <Button type="button" onPress={() => setSubjectModalIsVisible(true)} variant='shadow' color='default'>{selectedSubjects.size > 0 ? "Modificar" : "Seleccionar materias"}</Button>
          </div>


        </div>
        <div className='flex gap-4 w-full items-center justify-center'>
          <Button className='mt-8' type="button" onPress={resetFormCourse} variant='shadow' color='warning' isLoading={isLoadingNewSubject} >Limpiar</Button>
          <Button className='mt-8' type="submit" variant='shadow' color='secondary' isLoading={isLoadingNewSubject} >Guardar</Button>
        </div>
      </div>

    </NewSubjectFormContainer >
  );
}

export default NewCourseForm;