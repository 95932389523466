import React from "react";
import {
    Modal,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
} from "@nextui-org/react";
import TableSubjectsActives from "../TableSubjectsActives";

const SelectSubjectsModal = ({ visible, closeHandler, loading, subjects, selectedKeys, setSelectedKeys }) => {


    return (
        <Modal backdrop="blur" size="5xl" isOpen={visible} onClose={closeHandler}>
            <ModalContent>
                {(onClose) => (
                    <>
                        <ModalHeader
                            className="flex flex-col gap-1"
                            style={{ color: "black" }}
                        >
                            Seleccionar materias del curso
                        </ModalHeader>
                        <ModalBody className="max-h-96 w-full overflow-y-scroll">
                            <div className="flex flex-col gap-1 w-full bg-white p-2 rounded-lg text-black">
                                <TableSubjectsActives subjects={subjects} selectedKeys={selectedKeys} setSelectedKeys={setSelectedKeys} />
                            </div>

                        </ModalBody>
                        <ModalFooter className="w-full flex justify-center">
                            <Button auto flat onClick={closeHandler}>
                                Volver
                            </Button>
                            <Button
                                isDisabled={loading}
                                color="success"
                                style={{ color: "white" }}
                                auto
                                onClick={closeHandler}
                                isLoading={loading}
                            >
                                Confirmar
                            </Button>
                        </ModalFooter>
                    </>
                )}
            </ModalContent>
        </Modal>
    );
};

export default SelectSubjectsModal;
