import styled from "styled-components";

const NewSubjectFormContainer = styled.form`
  height: 100%;
  overflow-y: scroll;
  width: 100%;
  background-color: #202830;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 20px;
`;

export default NewSubjectFormContainer;
