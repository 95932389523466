import React, { useState } from "react";
import PropTypes from "prop-types";
import ItemNavContainer from "./styled-components/ItemNavContainer";
import ImagePerfilTest from "@assets/test-icon.svg";
import SubMenuIcon from "@assets/navIcons/submenu.svg";
import Icon from "@components/Icon/Icon";
import ItemName from "./styled-components/ItemName";

const ItemNav = ({ item, navIsOpen, onClickItem, tabActive }) => {
  const [activeSubmenu, setActiveSubmenu] = useState(null);
  const { pathname } = window.location;

  const onClickMenu = (path) => {
    if (activeSubmenu && activeSubmenu[0]?.id === item?.id) {
      setActiveSubmenu(null);
    }
    else {
      if (item?.submenu) {
        setActiveSubmenu(item.submenu);
      }
      else {
        onClickItem(path);
      }
    }
  };

  return (
    <>
      <ItemNavContainer
        onClick={() => onClickMenu(item?.path)}
        isActive={item.path === pathname}
      >
        <Icon
          src={item.image || ImagePerfilTest}
          width="20px"
          height="20px"
        />
        {navIsOpen && <ItemName>{item?.label}</ItemName>}
        {item?.submenu &&
          <Icon
            src={SubMenuIcon}
            width="15px"
            height="15px"
            className="mt-1"
          />
        }
      </ItemNavContainer>

      {activeSubmenu && activeSubmenu[0]?.id === item?.id && (
        activeSubmenu.map((subitem, subIndex) => (
          <ItemNavContainer
            key={subIndex}
            onClick={() => onClickItem(subitem?.path)}
            style={{ marginLeft: '1rem' }}
            isActive={subitem?.path === pathname}
          >
            <Icon
              src={subitem.image || ImagePerfilTest}
              width="20px"
              height="20px"
            />
            {navIsOpen && <ItemName>{subitem?.label}</ItemName>}
          </ItemNavContainer>
        ))
      )}
    </>
  );
};

ItemNav.propTypes = {
  onClickItem: PropTypes.func,
  item: PropTypes.object,
  navIsOpen: PropTypes.bool,
};

export default ItemNav;
