import axios from "../axios";
import { createSubjectURL } from "./URLs";

const createSubject = async (dataSubject) => {
    try {
        const response = await axios().post(createSubjectURL, dataSubject);
        return response || {};
    } catch (error) {
        return error.response;
    }
};

export default createSubject;
