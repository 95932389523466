import React from "react";
import PropTypes from "prop-types";
import ItemNav from "../ItemNav";

function NavigationItems({ onClickItem, navIsOpen, menu }) {
  return menu?.map((item) => {
    return (
      <ItemNav
        navIsOpen={navIsOpen}
        item={item}
        onClickItem={onClickItem}
      ></ItemNav>
    );
  });
}

NavigationItems.propTypes = {
  onClick: PropTypes.func,
  navIsOpen: PropTypes.bool,
};

export default NavigationItems;
