export const VIEWS_LOGIN = {
    LOGIN: "login",
    REGISTER: "register",
    RENEW_PASSWORD: "renew-password",
    NOTIFICATION_EMAIL: "notification-email",
};

export const ROLES = {
    STUDENT: "STUDENT",
    ADMIN: "ADMIN",
    PROFESSOR: "PROFESSOR",
};

export const CONTENT_TYPES = {
    PDF: 'pdf',
    PNG: 'png',
    JPG: 'jpg',
    JPEG: 'jpeg',
}

export const EVALUATION_TYPE = {
    MULTIPLE_CHOIS: "MULTIPLE_CHOIS",
    SINGLE_RESPONSE: "SINGLE_RESPONSE",
    ATTACH: "ATTACH"
}

export const PAYMENT_PLAN = [
    { key: 1, label: "1 cuota" }, { key: 2, label: "2 cuotas" }, { key: 3, label: "3 cuotas" },
    { key: 4, label: "4 cuotas" }, { key: 5, label: "5 cuotas" }, { key: 6, label: "6 cuotas" }, { key: 7, label: "7 cuotas"},
    { key: 8, label: "8 cuotas" }, { key: 9, label: "9 cuotas" }, { key: 10, label: "10 cuotas" }, { key: 11, label: "11 cuotas" }, { key: 12, label: "12 cuotas" }
];
