import axios from "../axios";
import { createCourseURL } from "./URLs";

const doCreateCourse = async (dataCourse) => {
    try {
        const response = await axios().post(createCourseURL, dataCourse);
        return response || {};
    } catch (error) {
        return error.response;
    }
};

export default doCreateCourse;
