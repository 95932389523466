/* eslint-disable no-shadow,react/jsx-no-constructed-context-values */
import React, { useContext, useEffect, useState, createContext } from "react";
import doLogout from "@services/auth/doLogout";
import getUser from "@services/auth/getUser";
import ROUTES from "../../components/Router/routes";

export const UserContext = createContext({});

export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children, context }) => {
  const [user, setUser] = useState(context);
  const { pathname } = window.location;
  const [isUserFetched, setIsUserFetched] = useState(false);
  const doLogOut = async () => {
    try {
      await doLogout();
      setUser({});
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  const fetchUserLogged = () => {
    getUser().then((data) => {
      setUser(data.data);
      setIsUserFetched(true);
    });
  };

  useEffect(() => {
    if (user) return;
    getUser().then((data) => {
      setUser(data.data);
      setIsUserFetched(true);
    });
  }, [user]);

  useEffect(() => {
    if (user) return;
  }, [user]);

  return (
    <UserContext.Provider
      value={{
        user,
        isUserFetched,
        setUser,
        isUserLogged: !!user?.name,
        doLogOut,
        fetchUserLogged,
        isRecoveryPath: pathname === ROUTES.RECOVERY,
        isLinkExpired: pathname === ROUTES.EXPIRED,
        needUpdateProfile: !!user?.needUpdateProfile,
        // isUserAdmin: user.role === ROLES.ADMIN,
        // isUserStudent: user.role === ROLES.STUDENT,
        // isUserProfessor: user.role === ROLES.PROFESSOR,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
