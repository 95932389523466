import axios from "../axios";
import { getSubjectsURL } from "./URLs";

const getSubjects = async () => {
    try {
        const response = await axios().get(getSubjectsURL);
        return response || {};
    } catch (error) {
        return error.response;
    }
};

export default getSubjects;