import ROUTES from "@components/Router/routes";
import UsersIcon from "@assets/navIcons/users.svg";
import NewIcon from "@assets/navIcons/newSubjectBis.svg";
import Findsubject from "@assets/navIcons/findSubject.svg";
import HomeIcone from "@assets/navIcons/home.svg";
import CoursesIcon from "@assets/navIcons/course.svg";
import CoursesIconGestion from "@assets/navIcons/course-gestion.svg";
import NewCourseIcon from "@assets/navIcons/new-course.svg";
import GestionSubject from "@assets/navIcons/gestion-subject.svg";
import MyAccount from "@assets/navIcons/profile.svg";
import Payment from "@assets/navIcons/payment.svg";
import CouponIcon from "@assets/navIcons/coupon.svg";
import IconCertificate from "@assets/navIcons/certificate.png";

const getPrivateLinks = ({
  userCanUpdateUsers,
  userIsProfessor,
  isUserLogged,
}) => {
  const links = [];
  // const items = [];

  const generalLinks = [
    {
      label: "Mis materias",
      path: ROUTES.HOME,
      image: HomeIcone,
    },
    {
      label: "Cursos disponibles",
      path: ROUTES.COURSES,
      image: CoursesIcon,
    },
  ];

  generalLinks.forEach((link) => {
    links.push(link);
  });

  if (userCanUpdateUsers) {
    links.push({
      id: 1,
      label: "Usuarios",
      path: ROUTES.USERS,
      image: UsersIcon,
    });
    links.push({
      id: 2,
      label: "Gestión Cupones",
      path: ROUTES.GESTION_COUPONS,
      image: CouponIcon,
    });
  }

  if (userCanUpdateUsers || userIsProfessor) {
    links.push({
      id: 3,
      label: "Gestión Materias",
      image: GestionSubject,
      submenu: [
        { id: 3, label: "Alta Materia", path: ROUTES.GESTION_SUBJECT_NEW, image: NewIcon, },
        { id: 3, label: "Consultar materias", path: ROUTES.GESTION_SUBJECT, image: Findsubject, }
      ],
    });
    links.push({
      id: 4,
      label: "Gestión Cursos",
      image: CoursesIconGestion,
      submenu: [
        { id: 4, label: "Alta Curso", path: ROUTES.GESTION_COURSE_NEW, image: NewCourseIcon, },
      ],
    });
  }

  if (isUserLogged) {
    links.push({
      label: "Cuotas",
      path: ROUTES.PAYMENT_PLAN,
      image: Payment,
    });
    links.push({
      label: "Perfil",
      path: ROUTES.PROFILE,
      image: MyAccount,
    });
    links.push({
      label: "Escolaridad",
      path: ROUTES.SCHOLARSHIP,
      image: IconCertificate,
    });
  }

  return links;
};

export default getPrivateLinks;
