import React, {useEffect, useState} from "react";
import {Button} from "@nextui-org/react";
import {useLocation} from "react-router";
import {useNavigate} from "react-router-dom";
import EvaluationIcon from '@assets/evaluation-icon.png';
import CorrectEvaluationContainer from "./styled-components/CorrectEvaluationContainer";
import HeaderCorrectEvaluation from "./styled-components/HeaderCorrectEvaluation";
import CorrectEvaluationMainTitle from "./styled-components/CorrectEvaluationMainTitle";
import ActionEvaluationCorrectContainer from "./styled-components/ActionEvaluationCorrectContainer";
import Title from "@components/Title/Title";
import {useMutation} from "react-query";
import {enqueueSnackbar} from "notistack";
import queryString from "query-string";
import ScrollContent from "../../styled-components/ScrollContent";
import ROUTES from "@components/Router/routes";
import Icon from "@components/Icon/Icon";
import {Chip} from "@nextui-org/chip";
import SubTitle from "@components/SubTitle/SubTitle";
import useDevice from "@utils/useDevice";
import ListQuestionCalification from "./components/ListQuestionCalification";
import getResultsByResult from "@services/evaluations/getResultsByResult";
import {SiteLoader} from "@components/SiteLoader/SiteLoader";
import doSendCalification from "@services/evaluations/doSendCalification";
import {EVALUATION_TYPE} from "@utils/constants";

const SendCalification = () => {
    const {isMobile} = useDevice();
    const navigate = useNavigate();
    const location = useLocation();
    const evaluationData = queryString.parse(location.search)
    const {result, e} = evaluationData;

    const [evaluationContent, setEvaluationContent] = useState();

    const [calificationContent, setCalificationContent] = useState(
        {
            result: result,
            score: 0,
            enrolment: e,
            answers: []
        }
    );


    const {mutate: getEvaluationByResult, isLoading: isLoadingGetEvaluation} =
        useMutation(getResultsByResult, {
            onSuccess: (data) => {
                if (data.status !== 400) {
                    setEvaluationContent(data.data[0]);
                } else {
                    enqueueSnackbar(data.data.message, {
                        variant: "error",
                        persist: false,
                    });
                }
            },
            onError: (error) => {
                console.log('error')
                enqueueSnackbar(error.response.data.message, {
                    variant: "error",
                    persist: false,
                });
            },
        });

    const {mutate: sendCalification, isLoading: caca} =
        useMutation(doSendCalification, {
            onSuccess: (data) => {
                if (data.status !== 400) {
                    enqueueSnackbar('Evaluación calificada', {
                        variant: "success",
                        persist: false,
                    });
                    navigate({
                        pathname: ROUTES.GESTION_SUBJECT,
                    });
                } else {
                    enqueueSnackbar(data.data.message, {
                        variant: "error",
                        persist: false,
                    });
                }
            },
            onError: (error) => {
                console.log('error')
                enqueueSnackbar(error.response.data.message, {
                    variant: "error",
                    persist: false,
                });
            },
        });

    const onSendEvaluation = () => {
        sendCalification(calificationContent);
    }

    useEffect(() => {
        getEvaluationByResult({resultId: result});
    }, [])

    const getTotalScoreByAnswers = (arr) => {
        let totalScoreResult = 0

        arr?.map((item) => {
            totalScoreResult += item?.answer?.answerScore || 0;
        })
        return totalScoreResult;
    }

    useEffect(() => {
        setCalificationContent({
            ...calificationContent,
            score: getTotalScoreByAnswers(calificationContent?.answers)
        })

        console.log('calification', calificationContent);

    }, [calificationContent?.answers])


    const mockResponseWithMultipleChoisResponses = (answersMultipleChois) => {
        const mockResponse = [];
        answersMultipleChois?.map((answer) => {
            mockResponse.push({
                question: answer?.question?._id,
                title: answer?.question?.description?.title,
                answer: {
                    comment: '',
                    isCorrect: answer?.isCorrect,
                    score: answer?.isCorrect ? answer?.question?.score : 0
                }
            })
        })
        return mockResponse;
    }

    const mockResponseWithSimpleResponses = (simpleAnswers) => {
        const mockResponse = [];
        simpleAnswers?.map((answer) => {
            mockResponse.push({
                question: answer?.question?._id,
                title: answer?.question?.description?.title,
                answer: {
                    comment: '',
                    isCorrect: null,
                    score: 0
                }
            })
        })
        return mockResponse;
    }

    useEffect(() => {
        //Set data from service in the state calification
        const responsesMultipleChois = mockResponseWithMultipleChoisResponses(evaluationContent?.answers.filter((answer) => answer?.question?.type === EVALUATION_TYPE.MULTIPLE_CHOIS))
        const simpleResponses = mockResponseWithSimpleResponses(evaluationContent?.answers.filter((answer) => answer?.question?.type === EVALUATION_TYPE.SINGLE_RESPONSE))

        setCalificationContent({...calificationContent, answers: [...responsesMultipleChois, ...simpleResponses]})
    }, [evaluationContent])


    if (isLoadingGetEvaluation) return <SiteLoader/>;

    return (
        <CorrectEvaluationContainer>
            <HeaderCorrectEvaluation isMobile={isMobile}>
                <CorrectEvaluationMainTitle>
                    <div className="flex items-center p-2">
                        <div className="flex items-center">
                            <Icon src={EvaluationIcon} alt='evaluation' width="40px" height="40px"/>
                            <Title> {evaluationContent?.evaluation?.title} </Title>
                        </div>
                        <div className="w-full p-4">
                            <Chip color="warning" variant="flat"
                                  style={{
                                      width: "100%",
                                      height: "60px",
                                      margin: "0px 30px",
                                      fontSize: "18px",
                                      fontWeight: "600"
                                  }}>
                                Total: {calificationContent?.score}/{evaluationContent?.evaluation?.maxScore}
                            </Chip>
                        </div>
                        <div className="px-5">
                            <Title> <SubTitle>Alumno:</SubTitle> {evaluationContent?.studentName} </Title>
                        </div>
                    </div>
                    <div className="p-2">
                        <SubTitle> {evaluationContent?.description} </SubTitle>
                    </div>
                </CorrectEvaluationMainTitle>
                <ActionEvaluationCorrectContainer>
                    <Button css={{background: "linear-gradient(161.91deg, #4f8f84 4.96%, #014c3e 83.92%)"}}
                            onClick={onSendEvaluation}>
                        Enviar calificación
                    </Button>
                </ActionEvaluationCorrectContainer>
            </HeaderCorrectEvaluation>
            <ScrollContent>
                {
                    evaluationContent?.answers?.map((answer, index) => (
                        <ListQuestionCalification
                            id={answer?.question?._id}
                            question={answer?.question}
                            answer={answer}
                            evaluationContent={evaluationContent}
                            calificationContent={calificationContent}
                            setCalificationContent={setCalificationContent}
                            count={index + 1}
                        >
                        </ListQuestionCalification>
                    ))
                }
            </ScrollContent>
        </CorrectEvaluationContainer>
    );
};

export default SendCalification;
