import React, { useState } from "react";
import { useUser } from "@context/UserProvider";
import Logo from "@assets/logo-berea.png";

function MobileNavBarV3({ menu, onClickItem }) {
  const [showMenu, setShowMenu] = useState(false);
  const [activeSubmenu, setActiveSubmenu] = useState(null);

  const { doLogOut } = useUser();

  const onClickMenu = (path, item) => {
    setActiveSubmenu(!activeSubmenu);
    if (item?.submenu) {
      setActiveSubmenu(item.submenu);
    }
    else {
      onClickItem(path);
      setShowMenu(false);
    }
  };

  const handleMouseLeave = () => {
    setActiveSubmenu(null);
  };

  return (
    <nav className="bg-gray-800">
      <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
        <div className="relative flex h-16 items-center justify-between">
          <div className="absolute inset-y-0 left-0 flex items-center">
            <button
              type="button"
              className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
              aria-controls="mobile-menu"
              aria-expanded="false"
              onClick={() => setShowMenu(!showMenu)}
            >
              <span className="absolute -inset-0.5"></span>
              <svg
                className="block h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                />
              </svg>
              <svg
                className="hidden h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <div className="flex flex-1 items-center justify-end sm:items-stretch">
            <div className="flex flex-shrink-0 items-center">
              <img
                className="h-8 w-auto  rounded-3xl"
                src={Logo}
                alt="Berea"
                onClick={() => onClickMenu("/")}
              />
            </div>
          </div>
        </div>
      </div>

      {showMenu && (
        <div className="absolute bg-gray-800 z-30 w-3/4" id="mobile-menu">
          <div className="space-y-1 px-2 pb-3 pt-2">
            {menu?.map((item, index) => (
              <div
                key={index}
                onMouseEnter={() => onClickMenu(null, item)}
              >
                <a
                  onClick={() => !item?.submenu && onClickMenu(item?.path)}
                  className="text-gray-300 hover:bg-gray-700 hover:text-white block rounded-md px-3 py-2 text-base font-medium"
                  aria-current="page"
                >
                  {item?.label}
                </a>
                {activeSubmenu &&
                  activeSubmenu[0].id === item.id &&
                  activeSubmenu.map((subitem, subIndex) => (
                    <a
                      key={subIndex}
                      onClick={() => onClickMenu(subitem?.path)}
                      className="text-gray-300 hover:bg-gray-700 hover:text-white block rounded-md px-3 py-2 text-base font-medium ml-4"
                    >
                      {subitem?.label}
                    </a>
                  ))}
              </div>
            ))}
            <a
              href="#"
              className="text-gray-300 hover:bg-gray-700 hover:text-white block rounded-md px-3 py-2 text-base font-medium"
              onClick={doLogOut}
            >
              Salir
            </a>
          </div>
        </div>
      )
      }
    </nav >
  );
}

export default MobileNavBarV3;
