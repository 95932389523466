import React, { useEffect, useState } from "react";
import { Autocomplete, AutocompleteItem } from "@heroui/react";
import { useMutation } from "react-query";
import getProfessors from "@services/users/getProfessors";

function AutocompleteProfessor({ setIdProfessor }) {

  const [professorsList, setProfessorsList] = useState([]);

  useEffect(() => {
    fetchProfessors();
  }, [])

  const onSelectProfessor = (idProfessorSelected) => {
    if (idProfessorSelected) setIdProfessor(idProfessorSelected)
  }

  const myFilter = (textValue, inputValue) => {
    if (inputValue.length === 0) {
      return true;
    }

    textValue = textValue.normalize("NFC").toLocaleLowerCase();
    inputValue = inputValue.normalize("NFC").toLocaleLowerCase();

    return textValue.slice(0, inputValue.length) === inputValue;
  };

  const { mutate: fetchProfessors, isLoading: isLoadingProfessors } = useMutation(
    getProfessors,
    {
      onSuccess: ({ data }) => {
        if (data)
          setProfessorsList(data);
      }
    }
  );


  return (
    <Autocomplete
      allowsCustomValue
      className="max-w-xs"
      defaultFilter={myFilter}
      placeholder="Profesor"
      defaultItems={
        professorsList
      }
      isLoading={isLoadingProfessors}
      onSelectionChange={onSelectProfessor}
      aria-label="Profesor"
      isRequired
      errorMessage="Seleccionar un profesor"
    >
      {(item) => <AutocompleteItem className='text-black' key={item.key}>{item.label}</AutocompleteItem>}
    </Autocomplete>
  );
}

export default AutocompleteProfessor;
